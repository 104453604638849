<script>
export default {
  name: "WebcamImage",
  props: ["site", "webcam"],
  data() {
    return {
      playing: true,
      modalVisible: false,
      current: NaN,
      current_idx: 0,
      timerCount: 1,
    }
  },
  methods: {
    toggleModalVisible() {
      this.playing = !this.playing;
      this.modalVisible = !this.modalVisible;
    },
    timestampToTimezoneFromUtc(timestamp, timezone) {
      return new Date(Date.parse(timestamp)).toLocaleString("fi-FI", {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
  watch: {
    webcam: {
      handler() {
        this.current_idx = 0;
      },
      immediate: true,
    },
    timerCount: {
      handler(value) {
        if (value >= 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 250);
        } else {
          if (this.webcam.length > 0 && this.playing) {
            // timer reached zero, change webcam image
            this.current = this.webcam[this.current_idx];
            // update index for next picture
            this.current_idx++;
            if (this.current_idx >= this.webcam.length) {
              this.current_idx = 0;
            }
          }
          // reset timercount
          this.timerCount = 1;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  }
};
</script>

<template>
  <div v-show="modalVisible" class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true"
    role="dialog" style="display:block">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" @click="toggleModalVisible()" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <slot>
            <img v-bind:src="current.url" class="img-fluid mx-auto d-block" />
          </slot>
        </div>
      </div>
    </div>
  </div>

  <figure class="figure" v-if="current" placeholder-glow>
    <img v-bind:src="current.url" class="figure-img img-fluid rounded" @click="toggleModalVisible()" />
    <figcaption class="figure-caption text-light" :class="{ placeholder: !current.datetime }">
      Kuva otettu
      {{ timestampToTimezoneFromUtc(current.datetime, current.timezone) }}
    </figcaption>
  </figure>
  <div v-if="!current" class="spinner-border text-light" role="status">
    <span class="visually-hidden">Haetaan webcam kuvaa...</span>
  </div>
</template>

<style scoped>
</style>